import { GetStaticProps } from 'next';
import { dehydrate } from '@tanstack/react-query';
import { Language, Partner } from '@shared/types/enums';
import FrontPageContainer from '@web/components/FrontPage/FrontPageContainer';
import { prefetchFrontPage } from '@web/components/FrontPage/utils/frontPageUtils';
import {
  DEFAULT_REVALIDATE_TIME,
  defaultGetStaticPaths,
  defaultGetStaticProps,
} from '@web/utils/nextUtils';

const Index = () => {
  return <FrontPageContainer />;
};

export default Index;

export const getStaticPaths = defaultGetStaticPaths;

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale: lang, params } = context;

  const partner = params?.partner as Partner;

  const locale = lang as Language;

  const { queryClient } = await defaultGetStaticProps(context);

  await prefetchFrontPage({ partner, queryClient, locale });

  const dehydratedState = dehydrate(queryClient);

  return {
    props: {
      dehydratedState,
      partner,
    },
    revalidate: DEFAULT_REVALIDATE_TIME,
  };
};
