import { css } from '@emotion/react';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import StructuredTextWrapper from '@ui/components/Content/StructuredTextWrapper';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import {
  createLegacyTypography,
  createTypography,
} from '@ui-v2/utils/styleUtils';
import LandingPageCoverContainer from '../../LandingPage/LandingPageCoverContainer';
import useGetFrontPageData from '../hooks/useGetFrontPageData';

const FrontPageCoverContainer = () => {
  const { cover } = useGetFrontPageData();
  const {
    backgroundTextColor,
    image,
    subtitleColor,
    text,
    textAlign,
    titleColor,
    titleTypography,
    useFloatingSearchWidget,
  } = cover;

  return (
    <LandingPageCoverContainer
      image={image}
      shouldUseLocalStorage={false}
      useFloatingSearchWidget={useFloatingSearchWidget}
    >
      {text && (
        <Container>
          <Box
            borderRadius="cardPrimary"
            display={['none', 'none', 'block']}
            maxWidthCustom={
              backgroundTextColor ? ['none', 'none', 500] : 'none'
            }
            px={backgroundTextColor ? 24 : 0}
            py={backgroundTextColor ? 24 : 0}
            style={{
              background: backgroundTextColor ?? undefined,
            }}
          >
            <StructuredTextWrapper
              data={text as unknown as StructuredTextType | null | undefined}
              h1Styles={({ colours, typography }) => [
                titleTypography
                  ? createLegacyTypography(titleTypography)
                  : createTypography(typography.heading02),
                css`
                  margin: 0;
                  color: ${titleColor ||
                  colours.text.on.interactive.primary.default};
                  text-align: ${textAlign || 'unset'};
                `,
              ]}
              h2Styles={({ colours, spacings, typography }) => [
                createTypography(typography.body01),
                css`
                  margin-top: ${spacings['8']}px;
                  color: ${subtitleColor ||
                  titleColor ||
                  colours.text.on.interactive.primary.default};
                  text-align: ${textAlign || 'unset'};
                `,
              ]}
            />
          </Box>
        </Container>
      )}
    </LandingPageCoverContainer>
  );
};

export default FrontPageCoverContainer;
